import { LeftPatternSection } from "./optimize"

const HomeManageSection = () => (
    <div className="py-10 px-6 sm:px-10 lg:py-20 md:px-20 xl:px-24 max-w-[1536px] m-auto">
        <LeftPatternSection additionalClassname=""
                imgSrc="/images/home/homev2-zpattern-assets/home-manage.png"
                imageSize="mb-8 lg:mb-0"
                imgAlt="tab branch locations"
                iSrc="/images/home/homev2-zpattern-assets/icon-manage.svg"
                iAlt="icon speedometer"
                title="Manage Your Programs from a Custom Dashboard"
                text="Having the right information available to the right team members is important. That’s why SimpleWaste provides custom dashboard views."
                liText1="Track relevant information at a glance"
                liText2="Single-click service requests"
                liText3="SLA compliance monitoring"
        />
    </div>
)

export default HomeManageSection