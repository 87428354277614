import Button from "../button";
import ScheduleDemo from "../schedule-demo";

const HomeHeroSection = () => (
    <div className="flex h-full xl:h-screen"
        style={{
            backgroundImage:'url(/images/home/home-hero-bg-v2.png)',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
        }}
    >   
        <div className="max-w-[1536px] m-auto 
                        flex flex-col md:space-x-8 lg:space-x-4
                        md:grid grid-cols-11      
                        py-20 px-6 sm:px-10 md:px-20 xl:px-24"
        >
            <div className="col-span-7 md:col-span-6 lg:col-span-7 flex flex-col justify-center space-y-10">
                <h1 className="text-white h3 sm:h2 xl:h1">Waste Made Easy</h1>
                <div className="bg-white h-2 w-40"></div>
                <div className="space-y-5">
                    <p className="text-white subheading">
                        Optimize your waste management programs and&nbsp;
                        <span className="inline lg:block">enhance your company operations.</span>
                    </p>
                    <ScheduleDemo>
                        {(setIsModalOpen) => (
                            <Button onClick={() => setIsModalOpen(true)} size="l" className="bg-[#166534]">
                                BOOK A DEMO
                            </Button>
                        )}
                    </ScheduleDemo>
                </div>            
            </div>
            <div className="flex w-full justify-center 
                            md:justify-start mt-10 md:mt-0"
            >
                <img 
                    className="max-w-[323px]
                                sm:max-w-[380px]
                                md:max-w-[280px]
                                lg:max-w-[330px] 
                                xl:max-w-[430px]
                                2xl:w-[700px]" 
                    src="/images/home/home-hero-1-v2.png" 
                    alt="Phone" 
                />
            </div>
        </div>        
    </div>
)

export default HomeHeroSection