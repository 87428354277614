export interface MetricProps {
  textBold: string;
  textNormal: string;
  iconSrc: string;
  iconAlt: string;
}

export const Metric = ({
  textBold,
  textNormal,
  iconSrc,
  iconAlt,
}: MetricProps) => (
  <div className="flex flex-col space-y-6 text-center items-center lg:text-start lg:items-start">
    <img className="!w-20" src={iconSrc} alt={iconAlt} />
    <p className="text-base-dark">
      <span className="text-body-xl-bold">{textBold}</span>
      <br /> {textNormal}
    </p>
  </div>
);

const HomeDifferenceSection = () => (
  <div className="max-w-[1536px] m-auto">
    <div className="space-y-16 py-20 px-10 md:px-20 xl:px-24 xl:py-24 2xl:py-32 ">
      <div className="flex flex-col space-y-4">
        <h1 className="h3 text-base-dark">The SimpleWaste Difference</h1>
        <p className="text-body-md lg:text-body-lg">
          Rapidly recurring ROI, advanced support, and end-to-end functionality
          equip your team&nbsp;
          <span className="inline lg:block">
            for modern-era waste management.
          </span>
        </p>
      </div>
      <div className="hidden lg:flex flex-row space-x-10 lg:justify-between">
        <Metric
          textBold="Technology-powered"
          textNormal="optimization"
          iconSrc="/images/home/homev2-difference-icons/icon-technology-powered.svg"
          iconAlt="technology-powered"
        />
        <Metric
          textBold="Real-time"
          textNormal="visibility dashboard"
          iconSrc="/images/home/homev2-difference-icons/icon-real-time.svg"
          iconAlt="technology-powered"
        />
        <Metric
          textBold="Network"
          textNormal="procurement advantage"
          iconSrc="/images/home/homev2-difference-icons/icon-network.svg"
          iconAlt="technology-powered"
        />
        <Metric
          textBold="Sustainability"
          textNormal="and KPIs Reporting"
          iconSrc="/images/home/homev2-difference-icons/icon-sustainability.svg"
          iconAlt="technology-powered"
        />
        <Metric
          textBold="24/7"
          textNormal="Dedicated LIVE Support"
          iconSrc="/images/home/homev2-difference-icons/icon-24-7.svg"
          iconAlt="technology-powered"
        />
      </div>
      <div className="hidden sm:flex flex-row justify-evenly md:flex-col space-x-10 justify-between lg:hidden">
        <div className="flex flex-col space-y-9 md:flex-row md:space-y-0 justify-evenly md:mb-10 lg:mb-0">
          <Metric
            textBold="Technology-powered"
            textNormal="optimization"
            iconSrc="/images/home/homev2-difference-icons/icon-technology-powered.svg"
            iconAlt="technology-powered"
          />
          <Metric
            textBold="Real-time"
            textNormal="visibility dashboard"
            iconSrc="/images/home/homev2-difference-icons/icon-real-time.svg"
            iconAlt="technology-powered"
          />
          <Metric
            textBold="Network"
            textNormal="procurement advantage"
            iconSrc="/images/home/homev2-difference-icons/icon-network.svg"
            iconAlt="technology-powered"
          />
        </div>
        <div className="flex flex-col space-y-9 md:hidden md:flex-row md:space-y-0">
          <Metric
            textBold="Sustainability"
            textNormal="and KPIs Reporting"
            iconSrc="/images/home/homev2-difference-icons/icon-sustainability.svg"
            iconAlt="technology-powered"
          />
          <Metric
            textBold="24/7"
            textNormal="Dedicated LIVE Support"
            iconSrc="/images/home/homev2-difference-icons/icon-24-7.svg"
            iconAlt="technology-powered"
          />
        </div>
        <div className="hidden md:flex flex-col space-y-9 justify-evenly  md:flex-row md:space-y-0">
          <Metric
            textBold="Sustainability"
            textNormal="and KPIs Reporting"
            iconSrc="/images/home/homev2-difference-icons/icon-sustainability.svg"
            iconAlt="technology-powered"
          />
          <Metric
            textBold="24/7"
            textNormal="Dedicated LIVE Support"
            iconSrc="/images/home/homev2-difference-icons/icon-24-7.svg"
            iconAlt="technology-powered"
          />
        </div>
      </div>

      <div className="grid space-y-8 justify-center sm:hidden">
        <Metric
          textBold="Technology-powered"
          textNormal="optimization"
          iconSrc="/images/home/homev2-difference-icons/icon-technology-powered.svg"
          iconAlt="technology-powered"
        />
        <Metric
          textBold="Real-time"
          textNormal="visibility dashboard"
          iconSrc="/images/home/homev2-difference-icons/icon-real-time.svg"
          iconAlt="technology-powered"
        />
        <Metric
          textBold="Network"
          textNormal="procurement advantage"
          iconSrc="/images/home/homev2-difference-icons/icon-network.svg"
          iconAlt="technology-powered"
        />
        <Metric
          textBold="Sustainability"
          textNormal="and KPIs Reporting"
          iconSrc="/images/home/homev2-difference-icons/icon-sustainability.svg"
          iconAlt="technology-powered"
        />
        <Metric
          textBold="24/7"
          textNormal="Dedicated LIVE Support"
          iconSrc="/images/home/homev2-difference-icons/icon-24-7.svg"
          iconAlt="technology-powered"
        />
      </div>
    </div>
  </div>
);

export default HomeDifferenceSection;
