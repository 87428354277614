export interface MetricInnoProps {
    cost: string,
    subText: string,
    iconSrc: string,
    iconAlt: string
}

export const MetricInno = ({ cost, subText, iconSrc, iconAlt} : MetricInnoProps) => (
    <div className="grid">
        <div className="flex flex-col items-center text-center xs:w-36 sm:items-start sm:text-start space-x-0 space-y-6 w-36 md:w-42 lg:w-72 lg:space-y-0 lg:flex-row lg:space-x-6">
            <div className="w-12 h-12">
                <img src={iconSrc} alt={iconAlt} />
            </div>
            <div className="">
                <h1 className="h4 lg:h3">{cost}</h1>
                <p>{subText}</p>
            </div>
        </div>
    </div>
)


const HomeInnovationSection = () => (
    <div className="text-white grid px-6 sm:px-10 py-20 xl:py-24 2xl:py-32 px-6 sm:px-10 md:px-20"
        style={{
            backgroundColor: "#132631",
        }}
    >
        <div className="m-auto">
            <div className="flex flex-col space-y-24">
                <div className="flex flex-col space-y-8">
                    <div className="flex flex-col space-y-3 items-center">
                        <h1 className="h3 text-center">First-in-class 
                            <span className="block md:inline"> Innovation</span>
                        </h1>
                        <div className="h-2 w-40 bg-gradient-to-l from-[#86EFAC] to-[#67E8F9]"></div> 
                        <p className="pt-5 text-center subheading">Move your company forward with the industry leader.</p> 
                    </div>     
                </div>
                <div className="hidden sm:flex flex-col space-y-16">
                    <div className="flex space-x-6 md:space-x-20 lg:space-x-6 xl:space-x-32">
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-savings.svg"
                            iconAlt="coins"
                            cost="33%"
                            subText="Savings AVG"
                        />
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-operation.svg"
                            iconAlt="coins"
                            cost="+40%"
                            subText="Operations Reduction"
                        />
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-rfp.svg"
                            iconAlt="coins"
                            cost="+70%"
                            subText="RFP Process Reduction"
                        />
                    </div>
                    <div className="flex space-x-6 md:space-x-20 lg:space-x-6 xl:space-x-32">
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-year-exp.svg"
                            iconAlt="coins"
                            cost="14+"
                            subText="Years Experience"
                        />
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-software.svg"
                            iconAlt="coins"
                            cost="21+"
                            subText="Software Integrations"
                        />
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-ancillary.svg"
                            iconAlt="coins"
                            cost="15+"
                            subText="Ancillary Service Options"
                        />
                    </div>   
                </div>
                <div className="sm:hidden space-y-10">
                    <div className="flex space-x-6 justify-center xl:space-x-32">
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-savings.svg"
                            iconAlt="coins"
                            cost="33%"
                            subText="Savings AVG"
                        />
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-operation.svg"
                            iconAlt="coins"
                            cost="+40%"
                            subText="Operations Reduction"
                        />
                    </div>
                    <div className="flex space-x-6 justify-center xl:space-x-32">
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-rfp.svg"
                            iconAlt="coins"
                            cost="+70%"
                            subText="RFP Process Reduction"
                        />
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-year-exp.svg"
                            iconAlt="coins"
                            cost="14+"
                            subText="Years Experience"
                        />
                    </div>
                    <div className="flex space-x-6 justify-center xl:space-x-32">
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-software.svg"
                            iconAlt="coins"
                            cost="21+"
                            subText="Software Integrations"
                        />
                        <MetricInno 
                            iconSrc="/images/home/homev2-innovation-icons/icon-ancillary.svg"
                            iconAlt="coins"
                            cost="15+"
                            subText="Ancillary Service Options"
                        />
                    </div>
                </div>
            </div>            
        </div>        
    </div>
)

export default HomeInnovationSection