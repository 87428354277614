import { LeftPatternSection, RightPatternSection } from "./optimize";

const HomeAchievableSectiob = () => (
  <div className="py-10 px-6 mb-10 sm:px-10 md:px-20 lg:py-20 xl:px-24 max-w-[1536px] m-auto">
    <div className="hidden lg:block">
      <RightPatternSection
        additionalClassname=""
        imgSrc="/images/home/homev2-zpattern-assets/home-achievable.png"
        imageSize="mb-8 lg:mb-0"
        imgAlt="tab branch locations"
        iSrc="/images/home/homev2-zpattern-assets/icon-achievable.svg"
        iAlt="icon truck"
        title="Achieve Sustainability Goals"
        text="Centralizing waste stream management provides multiple areas of improvement, including the ability to consolidate raw data. Environmental compliance is an important part of sustainability. We can help."
        liText1="Analyze your environmental impact"
        liText2="Increase sustainability awareness"
        liText3="Reduce your carbon footprint"
      />
    </div>
    <div className="block lg:hidden">
      <LeftPatternSection
        additionalClassname=""
        imgSrc="/images/home/homev2-zpattern-assets/home-achievable.png"
        imageSize="mb-8 lg:mb-0"
        imgAlt="tab branch locations"
        iSrc="/images/home/homev2-zpattern-assets/icon-achievable.svg"
        iAlt="icon truck"
        title="Achieve Sustainability Goals"
        text="Centralizing waste stream management provides multiple areas of improvement, including the ability to consolidate raw data. Environmental compliance is an important part of sustainability. We can help."
        liText1="Analyze your environmental impact"
        liText2="Increase sustainability awareness"
        liText3="Reduce your carbon footprint"
      />
    </div>
  </div>
);

export default HomeAchievableSectiob;
