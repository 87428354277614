// v1
// import HomeCostCutLikeAProSection from "@app/components/home/cost-cut-like-a-pro";
// import HomeHeroSection from "@app/components/home/hero";
// import HomeManagementSection from "@app/components/home/management";
// import HomeScheduleDemoSection from "@app/components/home/schedule-demo";
// import HomeSwitchToSimpleWasteSection from "@app/components/home/switch-to-simplewaste";
// import HomeTechStackSection from "@app/components/home/tech-stack";
// import HomeWhyHaulersWorkWithUsSection from "@app/components/home/why-haulers-work-with-us";
// import HomeWhyUserLoveSimpleWasteSection from "@app/components/home/why-user-love-simplewaste";

// v2
import HomeHeroSection from "@app/components/home-v2/hero";
import HomeDifferenceSection from "@app/components/home-v2/difference";
import HomeInnovationSection from "@app/components/home-v2/innovation";
import HomeOutcomeSection from "@app/components/home-v2/outcomes";
import HomeWhyUserLoveSimpleWasteSection from "@app/components/home-v2/why-user-love-sw";
import HomeOptimizeSection from "@app/components/home-v2/optimize";
import HomeRealTimeSection from "@app/components/home-v2/real-time";
import HomeManageSection from "@app/components/home-v2/manage";
import HomeAchievableSectiob from "@app/components/home-v2/achievable";
import HomeWasteManagementSection from "@app/components/home-v2/waste-management";
import HomeScheduleDemoSection from "@app/components/home/schedule-demo";
import { Fragment } from "react";


// v1
// const HomePage = () => <Fragment>
//     <HomeHeroSection />
//     <HomeManagementSection />
//     {/* <HomeTechStackSection /> */}
//     <HomeCostCutLikeAProSection />
//     <HomeWhyUserLoveSimpleWasteSection />
//     <HomeSwitchToSimpleWasteSection />
//     <HomeWhyHaulersWorkWithUsSection />
//     <HomeScheduleDemoSection />
// </Fragment>

// v2
const HomePage = () => 
<Fragment>
    <div className="overflow-x-hidden">
        <HomeHeroSection />
        <HomeDifferenceSection />
        <HomeInnovationSection />     
        <HomeOutcomeSection />    
        <HomeWhyUserLoveSimpleWasteSection/>   
        <HomeOptimizeSection />
        <HomeRealTimeSection />
        <HomeManageSection />
        <HomeAchievableSectiob />
        <HomeWasteManagementSection />
    </div>
</Fragment>

export default HomePage;