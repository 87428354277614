import { CSSProperties, ReactNode } from "react";

export interface TextGradientProps {
  children: ReactNode;
  light?: boolean
  overrideClassName?: string,
}

const TextGradient = ({ children, light = false, overrideClassName = ''}: TextGradientProps) => <div
  style={{
    backgroundImage: light ? "linear-gradient(to right, #86EFAC, #67E8F9)"
      : "linear-gradient(to right, #06B6D4, #22C55E)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  }}
  className={`${overrideClassName}`}
>
  {children}
</div>

export default TextGradient;
