import { RightPatternSection } from "./optimize"
import { LeftPatternSection } from "./optimize"

const HomeRealTimeSection = () => (
    <div className="py-10 px-6 sm:px-10 md:px-20 lg:py-20 xl:px-24 flex max-w-[1536px] m-auto">
        <div className="hidden lg:block">
            <RightPatternSection additionalClassname=""
                    imgSrc="/images/home/homev2-zpattern-assets/home-realtime.png"
                    imageSize=""
                    imgAlt="tab branch locations"
                    iSrc="/images/home/homev2-zpattern-assets/icon-realtime.svg"
                    iAlt="icon calendar"
                    title="Real-Time Visibility Provides Accurate Performance Insights"
                    text="Real-time tracking is crucial to increasing your company’s productivity goals. When your team is supplied with accurate data, this information provides valuable forecasting insights that allow departments to focus on their core responsibilities, reaching higher levels of efficiency."
                    liText1="Provide company-wide visibility"
                    liText2="Easily measure data analytics"
                    liText3="Eliminate effort redundancy"
            />
        </div>
        <div className="block lg:hidden">
            <LeftPatternSection additionalClassname=""
                    imgSrc="/images/home/homev2-zpattern-assets/home-realtime.png"
                    imageSize=""
                    imgAlt="tab branch locations"
                    iSrc="/images/home/homev2-zpattern-assets/icon-realtime.svg"
                    iAlt="icon calendar"
                    title="Real-Time Visibility Provides Accurate Performance Insights"
                    text="Real-time tracking is crucial to increasing your company’s productivity goals. When your team is supplied with accurate data, this information provides valuable forecasting insights that allow departments to focus on their core responsibilities, reaching higher levels of efficiency."
                    liText1="Provide company-wide visibility"
                    liText2="Easily measure data analytics"
                    liText3="Eliminate effort redundancy"
            />
        </div>
    </div>
)

export default HomeRealTimeSection