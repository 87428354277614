import Input from '@app/components/form/input'
import PhoneInput from '@app/components/phone-input'
import Button from '@app/components/button'
import Dropdown from '@app/components/form/dropdown'
import { Form } from 'react-final-form'
import { toast } from "react-toastify";

import { frontendApi } from "@app/lib/api";

const DropdownOptions = [
  { name: 'I want to learn more about your software', value: 1 },
  { name: 'I am interested in becoming a preffered vendor', value: 2},
  { name: 'Others', value: 3 },
]

const handleSubmit = async (payload, form) => {
  const selectedMessage = DropdownOptions.filter((item) => item.value === Number(payload.message))[0]

  const newPayload = {
    name: `${payload.firstName} ${payload.lastName}`,
    company: payload.companyName,
    email: payload.businessEmail,
    phone: payload.phone,
    message: selectedMessage ? selectedMessage.name : '',
  }

  await frontendApi.post('/web/get-in-touch', newPayload)
    .then((res) => {
      console.log(payload)
      toast.success(res.data.message, { position: "bottom-right" });
      form.reset()
    })
    .catch((err) => toast.error('Something went wrong'))
}


const HomeWasteManagementSection = () => (
    
    <div
        style = {{
            backgroundImage: "url(/images/home/home-hero-bg.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
        }}
    >
        <div className="text-white py-20 px-6 sm:px-10 md:px-20 xl:px-24 max-w-[1536px] m-auto">
            <div className="flex flex-col space-y-24">
                <div className="grid grid-cols-6">
                    <div className="col-span-6 xl:col-span-4 flex flex-col space-y-6">
                        <h1 className="h3">Let’s Talk About Transforming Your Waste Management Operations!</h1>
                        <p>
                            Talk to an agent about how SimpleWaste can help you streamline 
                            your waste management programs.
                        </p>
                    </div>
                    <div className="hidden md:block col-span-2"></div>
                </div>
                <div className="flex flex-col !space-x-0  item-center justfy-center lg:grid grid-cols-6 space-y-10 xl:!space-x-10">
                    <div className="col-span-6 xl:col-span-3 m-auto space-y-4">
                        <div className="flex justify-evenly">
                            <img className='w-[84px]' src="/images/home/homev2-waste-management-assets/icon-support.svg" alt="" />
                            <img className='w-[84px]' src="/images/home/homev2-waste-management-assets/icon-tech.svg" alt="" />
                            <img className='w-[84px]' src="/images/home/homev2-waste-management-assets/icon-procurement.svg" alt="" />
                        </div>
                        <div>
                            <img src="/images/home/homev2-waste-management-assets/waste-management.png" alt="" className="xl:w-[700px] h-auto" />
                        </div>
                    </div>
                    <div className="col-span-6 lg:col-start-2 col-end-6 xl:col-span-3">
                        <Form 
                            onSubmit={handleSubmit}
                            render={({
                                handleSubmit, form
                            }) => (
                            <form onSubmit={handleSubmit} className="shadow-lg space-y-6 bg-white rounded-lg border-[#D0D5DD] py-16 px-10">
                                <div className="grid space-x-0 space-y-6 sm:space-y-0 sm:flex sm:space-x-5">
                                    <div className='w-full'>
                                        <Input label="First Name*" name="firstName" />
                                    </div>
                                    <div className='w-full'>
                                        <Input label="Last Name*" name="lastName" />
                                    </div>
                                </div>
                                <div className="grid space-x-0 space-y-6 sm:space-y-0 sm:flex sm:space-x-5 items-center">
                                  <div className='w-full'>
                                        <PhoneInput
                                          initialValue="us"
                                          name="phone"
                                          label="Phone Number*"
                                          labelClassName="text-text-color-high-em label-md"
                                          phoneInputClassName="text-text-color-mid-em focus:bg-white focus:ring-0 focus:border-secondary-light !w-full !h-12 !rounded-lg"
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <Input label="Business Email*" name="businessEmail"/>
                                    </div>
                                </div>   
                                <Input label="Company Name*" name="companyName"/>
                                <Dropdown
                                  name="message"
                                  label="How can we help you?*"
                                  selectId=''
                                  options={DropdownOptions}
                                />
                                <div className='flex justify-end'>
                                    <Button type="submit" className="bg-gradient-to-r to-[#22C55E] from-[#06B6D4] hover:to-[#86EFAC] hover:from-[#67E8F9]" size='l'>Contact Sales</Button>  
                                </div>                       
                            </form>   
                        )}
                        />                                            
                    </div>
                </div>
            </div>           
        </div>
    </div>
)

export default HomeWasteManagementSection