export interface ZPatternSectionProps {
    imgSrc: string,
    imgAlt: string,
    title: string,
    text: string,
    iSrc: string,
    iAlt: string,
    liText1: string,
    liText2: string,
    liText3: string,
    additionalClassname: string,
    imageSize: string
}

export interface ZPatternListProps {
    iconSrc: string,
    iconAlt: string
}

export const ZPatternList = ({ iconSrc, iconAlt } : ZPatternListProps) => (   
    <span className="mr-4"><img src={iconSrc} alt={iconAlt} /></span> 
)

export const LeftPatternSection = ({ imgSrc, imgAlt, title, text, iSrc, iAlt, liText1, liText2, liText3, additionalClassname, imageSize } : ZPatternSectionProps) => (
    // <div className={`flex items-center ${additionalClassname} 
    //                 max-w-[1536px] m-auto flex-col space-y-10 justify-between`
    // }>
    <div className="flex flex-col lg:grid grid-cols-6 gap-10">
        <div className="col-span-3 flex justify-center items-center">
            <img 
                className={imageSize} 
                src={imgSrc} 
                alt={imgAlt} 
            />
        </div>
        
        <div className="col-span-3 space-y-4">
            <img src={iSrc} alt={iAlt} />
            <h1 className="h4 text-base-dark">{title}</h1>
            <p>{text}</p>
            <div className="pt-3 ml-4">
                <ul className="space-y-6">
                    <li className="flex">
                        <ZPatternList 
                            iconSrc="/images/home/homev2-zpattern-assets/li-check-icon.svg"
                            iconAlt="check"                            
                        />
                        {liText1}
                    </li>  
                    <li className="flex">
                        <ZPatternList 
                            iconSrc="/images/home/homev2-zpattern-assets/li-check-icon.svg"
                            iconAlt="check"                            
                        />
                        {liText2}
                    </li> 
                    <li className="flex">
                        <ZPatternList 
                            iconSrc="/images/home/homev2-zpattern-assets/li-check-icon.svg"
                            iconAlt="check"                            
                        />
                        {liText3}
                    </li>                  
                </ul>
            </div>
        </div>
    </div>    
)

export const RightPatternSection = ({ imgSrc, imgAlt, title, text, iSrc, iAlt, liText1, liText2, liText3, additionalClassname, imageSize } : ZPatternSectionProps) => (
    <div className="flex flex-col lg:grid grid-cols-6 gap-10">   
        <div className="col-span-3 space-y-4">
            <img src={iSrc} alt={iAlt} />
            <h1 className="h4 text-base-dark">{title}</h1>
            <p>{text}</p>
            <div className="pt-3 ml-4">
                <ul className="space-y-6">
                    <li className="flex">
                        <ZPatternList 
                            iconSrc="/images/home/homev2-zpattern-assets/li-check-icon.svg"
                            iconAlt="check"                            
                        />
                        {liText1}
                    </li>  
                    <li className="flex">
                        <ZPatternList 
                            iconSrc="/images/home/homev2-zpattern-assets/li-check-icon.svg"
                            iconAlt="check"                            
                        />
                        {liText2}
                    </li> 
                    <li className="flex">
                        <ZPatternList 
                            iconSrc="/images/home/homev2-zpattern-assets/li-check-icon.svg"
                            iconAlt="check"                            
                        />
                        {liText3}
                    </li>                  
                </ul>
            </div>
        </div>
        <div className="col-span-3 flex justify-center items-center">
            <img 
                className={imageSize}
                src={imgSrc} 
                alt={imgAlt} 
            />
        </div>
</div>  
)



const HomeOptimizeSection = () => (
    <div className="py-10 px-6 sm:px-10 lg:py-20 md:px-20 xl:px-24 max-w-[1536px] m-auto">
            <LeftPatternSection additionalClassname=""
                imgSrc="/images/home/homev2-zpattern-assets/home-optimize.png"
                imageSize="mb-8 lg:mb-0"                 
                imgAlt="tab branch locations"
                iSrc="/images/home/homev2-zpattern-assets/icon-optimize.svg"
                iAlt="icon truck"
                title="Optimize your waste programs with centralized oversight control"
                text="When it comes to managing waste, there’s no substitute for system control. That’s why SimpleWaste’s platform is designed to centralize point-of-contact, data collection, and support for any sized operation."
                liText1="Manage thousands of locations effortlessly"
                liText2="Consolidate waste stream management"
                liText3="Standardize operational procedures"
            />
    </div>
)

export default HomeOptimizeSection