import PhoneInputField, { PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Field } from 'react-final-form';

// initial phone input implementation
export interface PhoneInputFieldProps extends PhoneInputProps {
  name: string
  initialValue?: string
  label?: string
  labelClassName?: string
  blockClassName?: string
  phoneInputClassName?: string
}

const PhoneInput = ({
  name,
  initialValue,
  blockClassName = 'block',
  label,
  labelClassName,
  phoneInputClassName,
}: PhoneInputFieldProps) => {
  const labelDefaultClassName = "text-text-color-high-em"
  const phoneInputDefaultClassName = "!w-full !h-12 !rounded-lg"

  return (
    <Field name={name} initialValue={initialValue}>
      {({ input, meta }) => (
          <label className={blockClassName} htmlFor={name}>
          <span
            className={labelClassName ? labelClassName : labelDefaultClassName}
          >
            {label}
          </span>
          <PhoneInputField
          country={initialValue}
            placeholder=""
            autoFormat
            // containerClass="mt-1"
            inputClass={phoneInputClassName ? phoneInputClassName : phoneInputDefaultClassName}
            dropdownClass="text-text-color-high-em"
            {...input}
          />
        </label>
      )}
    </Field>
  )
}

export default PhoneInput