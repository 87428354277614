import { ReactNode, InputHTMLAttributes } from 'react';
import { Field } from 'react-final-form';

export interface optionsType {
  value: number,
  name: string,
}

export interface FormFieldDropdownProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode,
  name: string,
  blockClassName?: string,
  initialValue?: string,
  options: Array<optionsType>,
  selectId: string,
  placeholder?: string,

}

const FormFieldDropdown = ({
  label,
  name,
  initialValue,
  blockClassName = 'block',
  selectId,
  options,
  placeholder,
  ...inputProps
}: FormFieldDropdownProps) => (
  <Field name={name} initialValue={initialValue}>
    {({ input, meta }) => (
      <div className="w-full">
        <span className="label-md text-text-color-high-em
        dark:text-text-color-high-em-dark">{label}</span>
        <select
          className='focus:bg-white focus:ring-0 focus:border-secondary-light
          w-full rounded-lg border-[#D0D5DD] mt-1 text-text-color-mid-em'
          name={name}
          id={selectId}
          {...input}
        >
          <option value="">{placeholder}</option>
          {options.map(({ name, value }) =>
            <option
              key={value}
              value={value}
            >
              {name}
            </option>
          )}
        </select>
      </div>
    )}
  </Field>
);

export default FormFieldDropdown;
