import { ReactNode, InputHTMLAttributes } from 'react';
import { Field } from 'react-final-form';

export interface FormFieldInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode,
  name: string,
  blockClassName?: string
  initialValue?: string,
  readonly?: boolean
}

const FormFieldInput = ({
  label,
  name,
  initialValue,
  blockClassName = 'block',
  readonly = false,
  ...inputProps
}: FormFieldInputProps) => (
  <Field name={name} initialValue={initialValue}>
    {({ input, meta }) => (
      <label className={blockClassName}>
        <span
          className="label-md text-text-color-high-em
        dark:text-text-color-high-em-dark"
        >
          {label}
        </span>
        <input
          readOnly={readonly}
          type="text"
          className='h-12 mt-0 text-left
         w-full rounded-lg border-[#D0D5DD] text-text-color-mid-em focus:bg-white focus:ring-0 focus:border-secondary-light'
          {...input}
          {...inputProps}
        />
      </label>
    )}
  </Field>
);

export default FormFieldInput;
