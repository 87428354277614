import TextGradient from "@app/components/gradient/gradient-text";

const HomeOutcomeSection = () => (
    <div className="max-w-[1536px] m-auto">
        <div className="grid grid-cols-6 gap-10 items-center px-6 sm:px-10 py-20 xl:py-24 2xl:py-32 md:px-20 xl:px-24 2xl:gap-0">
            <div className="col-span-6 md:col-span-3 space-y-10 justify-center">
                <TextGradient overrideClassName="h4 lg:h3 xl:h2">
                    BUSINESS OUTCOMES
                </TextGradient>
                <div className="grid space-y-4">
                    <img 
                        src="/images/home/homev2-outcome-icons/icon-qoute-left.svg" 
                        alt="" 
                    />
                    <p className="text-text-color-high-em italic !font-medium h6 lg:h5">
                        The service and management features within the SimpleWaste platform have been a real game-changer. We’re experiencing lower costs and higher visibility for all levels of management within our company.
                    </p>  
                </div>  
                <div className="grid">
                    <p className="h6 text-text-color-high-em">- National Operator</p>
                </div>               
            </div>
            <div className="col-span-6 m-auto md:col-span-3 justify-end">
                <img 
                    src="/images/home/home-outcome-v2.png" 
                    alt="" 
                    className="md:h-[400px] lg:h-auto"
                />
            </div>
        </div>        
    </div>
)

export default HomeOutcomeSection